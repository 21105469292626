import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdowns", "textbox", "checkbox" ]

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (this.checkboxTarget.checked) {
      this.showTextBox();
    } else {
      this.showDropdowns();
    }
  }
  
  showTextBox() {
    this.dropdownsTarget.classList.add('d-none');
    this.textboxTarget.classList.remove('d-none');
    this.enableInputs(this.textboxTarget);
    this.disableInputs(this.dropdownsTarget);
  }
  
  showDropdowns() {
    this.dropdownsTarget.classList.remove('d-none');
    this.textboxTarget.classList.add('d-none');
    this.enableInputs(this.dropdownsTarget);
    this.disableInputs(this.textboxTarget);
  }

  toggle(event) {
    this.toggleVisibility()
  }

  enableInputs(container) {
    container.querySelectorAll("select, textarea, input").forEach(element => element.disabled = false);
  }
  
  disableInputs(container) {
    container.querySelectorAll("select, textarea, input").forEach(element => element.disabled = true);
  }
}